.project-container {
    width: calc(6rem + 40vw);
    height: calc(6rem + 25vw);
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    transition: transform 1s ease-in-out;
    font-size: 3vw;
}

.subtitle{
    font-size: 2.5vw;
}

.date{
    font-size: 2vw;
}

.nothovering {
    width: 90%;
    height: 90%;
}

.hovering {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0, 0.5);
    transition: all 0.3s ease-in;
}

.project-container .title {
    display: none;
}

.project-container .subtitle {
    display: none;
}

.project-container .date {
    display: none;
}

.project-container:hover .project .title {
    animation: translateUp 0.5s ease-in forwards;
    display: block;
}

.project-container:hover .project .date {
    animation: translateUp 0.5s ease-in forwards;
    display: block;
}

.project-container:hover .project .subtitle {
    animation: translateUp 0.5s ease-in forwards;
    display: block;
}

.nothovering {
    color: rgb(15 23 42);
}

@keyframes translateUp {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-3vw);
        color: white;
    }
}