.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0/0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}