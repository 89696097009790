.modal {
  width: clamp(70%, 1600px, 90%);
  height: 600px;
  margin: auto;
  padding: 0;
  padding-right: 2rem;
  border-radius: 12px;
  background: rgb(200, 233, 217);
  box-shadow: 0 10px 20px 0 rgb(0 0 0/0.5);
  overflow: auto;
  display: flex;
}

.modal-image {
  width: 40vw;
}


.modal-button {
  padding: 0 2rem;
  height: 2.5rem;
  margin-top: 1.5rem;
  background: #101111;
  color: #c5dae5;
  border-radius: 4px;
  transition: background ease 400ms;
  box-shadow: 0px 0px 10px #03030399;
}

.project-section {
  border-radius: 0.5rem;
  color: white;
  transition: filter 300ms;
  width: 40rem;
  margin-right: 1.2rem;
  margin-left: 1.2rem;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1rem;
  border: none;
  background-color: rgb(234, 248, 242);
}

.project-section:hover {
  filter: brightness(1.035)
}