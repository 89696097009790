:root {
    --primary3: rgb(110, 236, 118);
    --secondary3: #6bbcff;
    --primary4:rgb(110, 236, 118);
    --secondary4: #6bbcff;
    --dark2: #212121;
    --light2: #ffffff;
  }

.container{
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 0 15px;
    padding: 10px 20px;
    border-radius: 7px;
}

.title-text {
    text-align: center;
    background-image: linear-gradient(to bottom right, var(--primary3), var(--secondary3));
    color: #fff;
    font-weight: 400;
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.container .skill-box {
    width: 100%;
    margin: 25px 0;
}

.skill-box .title{
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.skill-box .skill-bar{
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(0,0, 0, 0.1);
}

.skill-box .skill-per{
    position: relative;
    display: block;
    height: 100%;
    border-radius: 6px;
    background-image: linear-gradient(to bottom right, var(--primary3), var(--secondary3));
    animation: progress 2s ease-in-out forwards;
    opacity: 0;
    animation-delay: 2.4s;
}

.skill-per.percent95{
    width: 95%;
    animation-delay: 2.6s;
}

.skill-per.percent90{
    width: 90%;
    animation-delay: 2.8s;
}

.skill-per.percent85{
    width: 85%;
    animation-delay: 3.0s;
}

.skill-per.percent80 {
    width: 80%;
    animation-delay: 3.2s;
}

.skill-per.percent75{
    width: 75%;
    animation-delay: 3.4s;
}

.skill-per.percent60 {
    width: 60%;
    animation-delay: 3.6s;
}
.skill-per.percent40 {
    width: 40%;
    animation-delay: 3.8s;
}



@keyframes progress {
    0% {
        width: 0;
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.skill-per .tooltip{
    position: absolute;
    right: -14px;
    top: -28px;
    font-size: 9px;
    font-weight: 500;
    color: #fff;
    padding: 2px 6px;
    border-radius: 3px;
    background-image: linear-gradient(to bottom right, var(--primary4), var(--secondary4));
    z-index: 1;
}

.tooltip::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background-image: linear-gradient(to bottom right, var(--primary4), var(--secondary4));
    transform: translateX(-50%) rotate(45deg);
}