.box {
    width: 768px;
    height: 768px;
}

@media screen and (max-width: 768px) {
    .box {
        background-color: red;
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .box {
        background-color: blue;
    }
    
}
